<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-body">
        <h5 class="g">
          {{ e("app_devices") }}
        </h5>
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered custom-table">
            <thead>
              <th>
                {{ e("user") }}
              </th>
              <th>
                {{ e("device-name") }}
              </th>
              <th>
                {{ e("status") }}
              </th>
              <th>
                {{ e("first_join") }}
              </th>
              <th>
                {{ e("last_active") }}
              </th>
              <th>
                {{ e("options") }}
              </th>
            </thead>
            <tbody>
              <template v-for="device in devices">
                <tr
                  :key="device._id"
                  v-if="!custom_member || custom_member == device.member_id"
                >
                  <td>
                    {{ device.member_name }}
                    <br />
                    {{ device.username }}
                  </td>
                  <td>
                    <i class="fa fa-mobile"></i>
                    {{ device.device_name }}
                    <br />
                    <i class="fa fa-barcode"></i>
                    {{ device.device_id }}
                  </td>
                  <td>
                    <span v-if="device.status == 0" class="badge bg-warning"
                      >في الانتظار</span
                    >
                    <span v-if="device.status == 1" class="badge bg-success"
                      >فعال</span
                    >
                    <span v-if="device.status == 2" class="badge bg-danger"
                      >متوقف</span
                    >
                  </td>
                  <td>
                    {{ device.first_join }}
                  </td>
                  <td>
                    {{ device.last_update }}
                  </td>
                  <td>
                    <b-dropdown
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :text="e('options')"
                      variant="relief-secondary"
                    >
                      <b-dropdown-item
                        @click="
                          current = device;
                          $bvModal.show('modal-1');
                        "
                      >
                        <i class="fa fa-edit text-primary"></i>
                        {{ e("edit-status") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteDevice(device._id)"
                        ><i class="fa fa-trash text-danger"></i>
                        {{ e("delete-device") }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr></template
              >
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" hide-footer>
      <div class="row">
        <div class="col-12 g">
          <button
            class="btn btn-lg btn-block btn-success"
            @click="changeDeviceStatus(1)"
          >
            <i class="fa fa-check"></i>
            {{ e("accept") }}
          </button>
        </div>
        <div class="col-12 g">
          <button
            class="btn btn-lg btn-block btn-outline-danger"
            @click="changeDeviceStatus(2)"
          >
            <i class="fa fa-times"></i>
            {{ e("reject") }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink,
  BOverlay,
  BCard,
  BDropdown,
  VBModal,
  BDropdownItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BLink,
    BOverlay,
    BCard,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      devices: [],
      members: [],
      current: {},
      user: JSON.parse(localStorage.getItem("user")),
      custom_member: window.location.hash
        ? window.location.hash.split("#")[1]
        : false,
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  created() {
    var _g = this;
    $.post(api + "/users/app-devices", {
      jwt: this.user.jwt,
    })
      .then(function (response) {
        response = JSON.parse(response);
        if (response.status == 100) {
          _g.devices = response.response;
        } else {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error"),
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        }
        _g.loading = false;
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: e("error"),
            icon: "TimesIcon",
            variant: "danger",
          },
        });
        _g.loading = false;
      });
  },
  methods: {
    e(ea) {
      return e(ea);
    },
    cdate: (date) => {
      return cdate(date);
    },
    deleteDevice(id) {
      var g = this,
        _g = g;
      if (confirm(e("confirm"))) {
        $.post(api + "/users/app-devices/delete", {
          jwt: this.user.jwt,
          id: id,
        })
          .then(function (response) {
            response = JSON.parse(response);
            if (response.status == 100) {
              $.post(api + "/users/app-devices", {
                jwt: g.user.jwt,
              })
                .then(function (response) {
                  response = JSON.parse(response);
                  if (response.status == 100) {
                    _g.devices = response.response;
                  } else {
                    _g.$toast({
                      component: ToastificationContent,
                      props: {
                        title: e("error"),
                        icon: "TimesIcon",
                        variant: "danger",
                      },
                    });
                  }
                  _g.loading = false;
                })
                .catch(function () {
                  _g.$toast({
                    component: ToastificationContent,
                    props: {
                      title: e("error"),
                      icon: "TimesIcon",
                      variant: "danger",
                    },
                  });
                  _g.loading = false;
                });
              alert(e("done"));
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("error"),
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
            _g.loading = false;
          })
          .catch(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e("error"),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
            _g.loading = false;
          });
      }
    },
    changeDeviceStatus(status) {
      var g = this,
        _g = g;
      if (confirm(e("confirm"))) {
        $.post(api + "/users/app-devices/status", {
          jwt: this.user.jwt,
          id: g.current._id,
          status: status,
        })
          .then(function (response) {
            response = JSON.parse(response);
            if (response.status == 100) {
              $.post(api + "/users/app-devices", {
                jwt: g.user.jwt,
              })
                .then(function (response) {
                  response = JSON.parse(response);
                  if (response.status == 100) {
                    _g.devices = response.response;
                  } else {
                    _g.$toast({
                      component: ToastificationContent,
                      props: {
                        title: e("error"),
                        icon: "TimesIcon",
                        variant: "danger",
                      },
                    });
                  }
                  _g.loading = false;
                })
                .catch(function () {
                  _g.$toast({
                    component: ToastificationContent,
                    props: {
                      title: e("error"),
                      icon: "TimesIcon",
                      variant: "danger",
                    },
                  });
                  _g.loading = false;
                });
              g.$bvModal.hide("modal-1");
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("error"),
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
            _g.loading = false;
          })
          .catch(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e("error"),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
            _g.loading = false;
          });
      }
    },
  },
};
</script>

<style>
</style>